@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'promptsemibold';
    src: url(./assets/fonts/prompt-semibold-webfont.woff2) format('woff2'),
         url(./assets/fonts/prompt-semibold-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
      font-family: 'promptregular';
      src: url(./assets/fonts/prompt-regular-webfont.woff2) format('woff2'),
          url(./assets/fonts/prompt-regular-webfont.woff) format('woff');
      font-weight: normal;
      font-style: normal;
  }

  html, body {
    font-family: 'promptregular', 'promptsemibold';
  }
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
